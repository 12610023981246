import * as React from 'react';

import { Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel } from '@mui/material';

import { AbstractMesh, Animation, AnimationGroup, ArcRotateCamera, CubeTexture, EasingFunction, Mesh, Scene, SceneLoader, SineEase, Vector3 } from "@babylonjs/core";
import SceneComponent from 'babylonjs-hook';
import './App.css';

if (process.env.NODE_ENV !== 'production') {
  require("@babylonjs/core/Debug/debugLayer");
  require("@babylonjs/inspector");
}

interface IState {
  loading: boolean;
  assemble: boolean;
  rotate: boolean;
  environment: boolean;
}

class App extends React.Component<{}, IState> {
  scene?: Scene;
  camera?: ArcRotateCamera;
  skybox?: Mesh;
  convertAnimation?: AnimationGroup;

  state: IState = {
    loading: true,
    assemble: true,
    rotate: true,
    environment: true,
  }

  componentWillUpdate(nextProps: Readonly<{}>, nextState: Readonly<IState>): void {
    if (nextState.assemble !== this.state.assemble)
      this.setAssemble(nextState.assemble)
    if (nextState.rotate !== this.state.rotate)
      this.setRotate(nextState.rotate)
    if (nextState.environment !== this.state.environment)
      this.setEnvironment(nextState.environment)
  }

  onSceneReady = async (scene: Scene) => {
    this.setState({ loading: true })
    this.scene = scene

    // 摄像机
    this.camera = new ArcRotateCamera("camera", 0, Math.PI / 2, 15, Vector3.Zero(), scene);
    this.camera.lowerRadiusLimit = 2;
    this.camera.upperRadiusLimit = 20;
    this.camera.attachControl(scene.getEngine().getRenderingCanvas(), true);
    this.camera.wheelPrecision = 50
    this.camera.useNaturalPinchZoom = true

    // 环境贴图
    scene.environmentTexture = CubeTexture.CreateFromPrefilteredData('/model/Runyon_Canyon_A_2k_cube_specular.dds', scene);
    this.skybox = scene.createDefaultSkybox(scene.environmentTexture, false, 1000, 0, false)!;

    // 载入模型
    await SceneLoader.ImportMeshAsync(['ACME2EW', 'BatteryPack', 'Body', 'Head'], '/model/all.babylon', undefined, scene);
    let acme2ew = scene.getMeshById('ACME2EW')!;
    let batteryPack = scene.getMeshById('BatteryPack')!;
    // let body = scene.getMeshById('Body')!;
    let head = scene.getMeshById('Head')!;

    // 动画
    let circleEase = new SineEase()
    circleEase.setEasingMode(EasingFunction.EASINGMODE_EASEINOUT);
    const acme2ewSlide = new Animation("ConvertUpSlide", "position.y", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CYCLE);
    acme2ewSlide.setKeys([
      { frame: 0, value: acme2ew.position.y },
      { frame: 80, value: acme2ew.position.y + .22 },
      { frame: 100, value: acme2ew.position.y + .6 },
      { frame: 180, value: acme2ew.position.y + .6 + .22 },
      { frame: 200, value: acme2ew.position.y + .6 + .6 },
    ]);
    acme2ewSlide.setEasingFunction(circleEase)
    const acme2ewRotate = new Animation("ConvertUpRotate", "rotation.y", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CYCLE);
    acme2ewRotate.setKeys([
      { frame: 0, value: 0 },
      { frame: 80, value: -Math.PI * 10 },
      { frame: 100, value: -Math.PI * 9.9 },
      { frame: 180, value: -Math.PI * 10 * 2 },
      { frame: 200, value: -Math.PI * 9.9 * 2 },
    ]);
    acme2ewRotate.setEasingFunction(circleEase)

    const batteryPackSlide = new Animation("ConvertDownSlide", "position.y", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CYCLE);
    batteryPackSlide.setKeys([
      { frame: 0, value: batteryPack.position.y },
      { frame: 80, value: batteryPack.position.y + .22 },
      { frame: 100, value: batteryPack.position.y + .6 },
      { frame: 200, value: batteryPack.position.y + .6 },
    ]);
    batteryPackSlide.setEasingFunction(circleEase)
    const batteryPackRotate = new Animation("ConvertDownRotate", "rotation.y", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CYCLE);
    batteryPackRotate.setKeys([
      { frame: 0, value: 0 },
      { frame: 80, value: -Math.PI * 10 },
      { frame: 100, value: -Math.PI * 9.9 },
      { frame: 200, value: -Math.PI * 9.9 },
    ]);
    batteryPackRotate.setEasingFunction(circleEase)

    const headSlide = new Animation("ConvertDownSlide", "position.y", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CYCLE);
    headSlide.setKeys([
      { frame: 0, value: head.position.y },
      { frame: 100, value: head.position.y },
      { frame: 180, value: head.position.y - .22 },
      { frame: 200, value: head.position.y - .5 },
    ]);
    headSlide.setEasingFunction(circleEase)
    const headRotate = new Animation("ConvertDownRotate", "rotation.y", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CYCLE);
    headRotate.setKeys([
      { frame: 0, value: 0 },
      { frame: 100, value: 0 },
      { frame: 180, value: Math.PI * 10 },
      { frame: 200, value: Math.PI * 9.9 },
    ]);
    headRotate.setEasingFunction(circleEase)

    this.convertAnimation = new AnimationGroup("ConvertAnimation");
    this.convertAnimation.addTargetedAnimation(acme2ewSlide, acme2ew);
    this.convertAnimation.addTargetedAnimation(acme2ewRotate, acme2ew);
    this.convertAnimation.addTargetedAnimation(batteryPackSlide, batteryPack);
    this.convertAnimation.addTargetedAnimation(batteryPackRotate, batteryPack);
    this.convertAnimation.addTargetedAnimation(headSlide, head);
    this.convertAnimation.addTargetedAnimation(headRotate, head);

    // 初始化
    this.setAssemble(this.state.assemble)
    this.setRotate(this.state.rotate)
    this.setEnvironment(this.state.environment)

    this.setState({ loading: false })
  }

  openDebugLayer = () => this.scene!.debugLayer.show()
  setAssemble = (v: boolean) => {
    let fromFrame = null
    const animatables = this.convertAnimation!.animatables
    if (animatables.length)
      fromFrame = animatables[0].masterFrame
    if (v) {
      this.convertAnimation!.stop()
      this.convertAnimation!.normalize(fromFrame ?? 200, 0)
      this.convertAnimation!.play()
    } else {
      this.convertAnimation!.stop()
      this.convertAnimation!.normalize(fromFrame ?? 0, 200)
      this.convertAnimation!.play()
    }
  }
  setRotate = (v: boolean) => {
    this.camera!.useAutoRotationBehavior = v
  }
  setEnvironment = (v: boolean) => {
    this.skybox!.isVisible = v
  }

  render() {
    return (
      <div className="main">
        <Dialog
          open={this.state.loading!}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Waiting ...</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" style={{ display: 'flex', alignItems: 'center', gap: 16 }} >
              <CircularProgress />
              Please wait model is loading.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
          </DialogActions>
        </Dialog>
        <div className='menu'>
          <div className='fill' />
          <FormControlLabel control={<Checkbox checked={this.state.assemble} onChange={(_, v) => this.setState({ assemble: v })} />} label="Assemble" />
          <FormControlLabel control={<Checkbox checked={this.state.rotate} onChange={(_, v) => this.setState({ rotate: v })} />} label="Rotate" />
          <FormControlLabel control={<Checkbox checked={this.state.environment} onChange={(_, v) => this.setState({ environment: v })} />} label="Environment" />
          {process.env.NODE_ENV !== 'production' ? <Button variant="contained" onClick={this.openDebugLayer}>Debug</Button> : null}
        </div>
        <div className="scene">
          <SceneComponent antialias adaptToDeviceRatio={false} onSceneReady={this.onSceneReady} />
        </div>
      </div>
    );
  }
}

export default App;
